import React from 'react';
import portfolio from '../../data/portfolio-data';
import PortfolioCard from './PortfolioCard';

const Portfolio = () => {
    return (
        <div className="parallax-content projects-content" id="portfolio">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div
                            id="owl-testimonials"
                            className="owl-carousel owl-theme"
                        >
                            {portfolio.map(project => {
                                return <PortfolioCard project={project} />
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
