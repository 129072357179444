const highlights = [
    {
        header: 'Experienced Mobile Developer',
        desc: `Built a strong level of experience developing both personal
        and enterprise-level React Native mobile apps. Well-versed in end-to-end processes
        from planning through deployment.`,
    },
    {
        header: 'Self Starter Meets Team Player',
        desc: `Fully capable and confident in exceeding client and consumer expectations
        for mobile application development, whether building an app from scratch on my own
        or collaborating with team members.`,
    },
    {
        header: 'Agile Development Specialist',
        desc: `Experienced with professional agile
        development practices to create
        iterative releases in rapid response to
        ever-changing customer and business
        requirements.`,
    },
    {
        header: 'Perpetual Learner',
        desc: `Never content to just know enough to get
        by, but determined to constantly learn
        the latest and greatest technologies
        through self-study and on-the-job
        experience.`,
    },
];

export default highlights;
