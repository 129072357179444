import React from 'react';

const PortfolioCard = ({project}) => {
    return (
        <div className="item">
            {console.log('this some bullshit')}
            <div className="testimonials-item">
                <a
                    href={project.href}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <img src={project.img_src} alt="" />
                </a>
                <div className="hover-over-text">
                    <h2>
                        <em>{project.hover_text}</em>
                    </h2></div>
                <div className="divider-bar-orange" />
                <div className="text-content">
                    <h1>{project.title}</h1>
                    <div className="divider-bar-black" />
                    <span>{project.date}</span>
                </div>
            </div>
        </div>
    );
};

export default PortfolioCard;