import React from 'react';

const Highlight = ({highlight}) => {
    return (
        <div className="col-md-6">
            <div className="service-item">
                <h4>{highlight.header}</h4>
                <div className="line-dec"></div>
                <p>{highlight.desc}</p>
            </div>
        </div>
    );
}

export default Highlight;