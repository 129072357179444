const portfolio = [
    {
        href: 'https://youtu.be/CYrBmv8Jm3o',
        img_src: 'img/LeaseraMobileDashboard.png',
        hover_text: `React Native front end, Firebase back end with GraphQL API middleware. 
        Built from the ground up to handle enterprise-level deployment and usage across both iOS and Android consumer devices.`,
        title: 'Leasera Mobile App',
        date: 'December 2020 - January 2022',
    },
    {
        href: 'https://github.com/RickMorrison2/StarSearch',
        img_src: 'img/StarSearch-min.jpg',
        hover_text: `React Native front end,
        Node.js/Express back end.
        Designed and built from scratch as a solo project and deployed to iOS and Android app marketplaces.`,
        title: 'StarSearch Mobile App',
        date: 'June 2019',
    },
    {
        href: 'https://edisonlive.com',
        img_src: 'img/EdisonLive-min.jpg',
        hover_text: `Vue.js with Vuetify front end,
        PHP with Laravel back end.
        Mobile and desktop friendly.`,
        title: 'EdisonLive.com',
        date: 'April 2020',
    },
    {
        href: 'https://edisoninteractive.com',
        img_src: 'img/EdisonInteractive-min.jpg',
        hover_text: `Vue.js with Vuetify front end,
        Node.js/Express back end. Mobile friendly.`,
        title: 'EdisonInteractive.com',
        date: 'November 2019',
    },
    // {
    //     href: 'https://react-burger-builder-a8da0.web.app',
    //     img_src: 'img/BurgerBuilder-min.png',
    //     hover_text: `React with Hooks and Redux front
    //     end, Firebase back end. Mobile
    //     responsive, includes auth to
    //     store and retrieve previous
    //     orders.`,
    //     title: 'React Burger Builder',
    //     date: 'September 2020',
    // },
    {
        href: 'https://github.com/RickMorrison2/AwJeezDotDev',
        img_src: 'img/OnewheelPose-min.jpg',
        hover_text: `React with Hooks front end, serverless Firebase back end. As always, mobile friendly.`,
        title: 'AwJeez.dev (This Site)',
        date: 'October 2020',
    },
];

export default portfolio;
