import React from 'react';
import highlights from '../../data/highlights';
import Highlight from './Highlight';
import Headline from './Headline';

const Highlights = (props) => {
    return (
        <div className="service-content" id="services">
            <div className="container">
                <div className="row">
                    <Headline />
                    <div className="col-md-8">
                        <div className="row">
                            {highlights.map(el => {
                                return <Highlight highlight={el} /> 
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Highlights;
