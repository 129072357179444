import React from 'react';

const Headline = () => {
    return (
        <div className="col-md-4">
            <div className="left-text">
                <h4>A Bit About Me</h4>
                <div className="line-dec"></div>
                <p>
                    I enjoy building clean, useful mobile apps. I specialize in
                    React Native to quickly develop cross-platform applications
                    for easy deployment to iOS and Android marketplaces.
                    <br />
                    <br />
                    I'd love to find a way to put those skills to work and
                    develop new ones. Think we could work together? Scroll to
                    check out my portfolio or drop me a line.
                </p>
                <div className="primary-button">
                    <a href="#portfolio">My Work</a>
                </div>
            </div>
        </div>
    );
};

export default Headline;
